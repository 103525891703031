import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "app-email-link",
    templateUrl: "./email-link.component.html",
    styleUrls: ["./email-link.component.scss"],
    encapsulation: ViewEncapsulation.None
})

export class EmailLinkComponent implements OnInit {

    @Input() address: string;
    @Input() subject: string;

    static domain: number[] = [
        64,
        99,
        104,
        114,
        111,
        110,
        111,
        102,
        111,
        114,
        109,
        46,
        98,
        97,
        110,
        100
    ];

    constructor() { }

    ngOnInit() {
    }

    static getDomain = () => {
        return EmailLinkComponent.domain.map(c => String.fromCharCode(c)).join("");
    };

    sendMail = () => {
        const address = (this.address || "contact") + EmailLinkComponent.getDomain();
        const subject = this.subject ? `?subject=${this.subject}` : "";

        const mailto = `mailto:${address}${subject}`;
        window.location.href = mailto;
    };
}
