<h2>Biography</h2>
<section class="biography-container">
  <p>
    Chronoform was
    <a target="_blank" href="https://www.youtube.com/watch?v=-oAGSEruSy0">formed</a>
    in 2013 by a group of
    <a target="_blank" href="https://kaaoszine.fi/kaaoszinen-isanmaan-toivot-chronoform/">friends</a>
    sharing a passion for the same type of music.
    Though the years have passed and lineup changes have happened, the same thing can still be found at the core of Chronoform.
    We do
    <a target="_blank" href="https://www.youtube.com/watch?v=BLc-L9myZI4">everything</a>
    for
    <a target="_blank" href="https://mikakarhumaa.fi/news/the-value-of-being-an-independent-artist-in-the-music-business/">ourselves</a>
    and that is one of the main reasons we tend to have so much
    <a target="_blank" href="https://kaaoszine.fi/hauskaa-ja-taidokasta-modernia-aarimetallia-arviossa-chronoformin-debyyttialbumi-from-the-void/">fun</a>
    while doing it.
    This can be most clearly seen as a lively, energetic and happy
    <a target="_blank" href="https://www.youtube.com/watch?v=bkT5pasOMyM">live</a>
    presence.
  </p>
  <p>
    <a target="_blank" href="https://www.altpress.com/features/new-metal-releases-finland-2020/">
      <span style="font-style: italic">"Chronoform surely know the formula for a seamless fusion of metal, hardcore, progressive and djent."</span>
      <br/>- Alternative Press
    </a>
  </p>
  <p>
    <a target="_blank" href="https://kaaoszine.fi/gootteja-keppanaa-ja-nostalgiaa-kolmatta-kertaa-jarjestetty-seinajoki-metal-festival-kokosi-kuuden-bandin-ja-genren-kavalkadin-etela-pohjanmaalle/">
      <span style="font-style: italic">"...besides an energetic live performance, there is a lot to be expected from this young band."</span>
      <br/>- KaaosZine
    </a>
  </p>
  <p>
    As a band, we started pretty much from scratch.
    Juha-Matti was the only one with previous experience of playing in a metal band, but the rest of us were total
    <a target="_blank" href="https://kaaoszine.fi/uutta-verta-25-taman-hetken-kiinnostavinta-nuorta-metalliyhtyetta-3/">rookies</a>.
    As soon as we had
    <a target="_blank" href="https://www.youtube.com/watch?v=hqKdnnhLhQM">composed</a>
    a live set’s worth of songs, we immediately hit the stage at a show we organized ourselves.
    From that point, it was clear that everyone was more or less
    <a target="_blank" href="https://tuonelamagazine.com/review-chronoform-from-the-void/">hooked</a>
    and this was the thing we wanted to do
    <a target="_blank" href="https://www.altpress.com/features/new-metal-releases-finland-2020/">more</a>.
  </p>
  <p>
    Our music can be described as
    <a target="_blank" href="https://www.impe.fi/articles/1/2967">melancholic</a>,
    <a target="_blank" href="http://deadrhetoric.com/reviews/chronoform-from-the-void-inverse/">groovy</a>,
    <a target="_blank" href="http://www.metal-temple.com/site/catalogues/entry/reviews/cd_3/c_2/chronoform-from-the.htm">hard-hitting</a>
    metal.
    We all have our own influences which can be heard in the music we create.
    However, we have a very distinct,
    <a target="_blank" href="https://cadavergarden.wpcomstaging.com/2019/05/31/chronoform-from-the-void/">easily-recognizable</a>
    sound.
    Composing music and writing
    <a target="_blank" href="https://www.inferno.fi/uutiset/addiktin-tarina-ongelman-tajuamisesta-kuolemaan-saakka-ennakkokuuntelussa-chronoformin-toinen-pitkasoitto/">lyrics</a>
    is a special outlet for most of us, which is why we tend to share a very
    <a target="_blank" href="https://www.youtube.com/watch?v=Yh5tRnRpc20">personal</a>
    connection with our material.
    We hope that it can also be
    <a target="_blank" href="http://www.rockgarage.it/?p=79756">heard</a>
    by
    <a target="_blank" href="https://www.rytmi-instituutti.fi/promoamo-marko-kivela/">you</a>.
  </p>
  <p>
    To this day, we have released two full length albums:
    <a target="_blank" href="https://chronoform.bandcamp.com/">From the Void</a>
    (2019) and
    <a target="_blank" href="https://www.youtube.com/watch?v=pmUShpIgS3g">Myoni</a>
    (2020) and played shows all around Finland, most notably at
    <a target="_blank" href="https://kaaoszine.fi/nummirock-julkisti-viimeiset-festivaalilisaykset-mukana-anal-thunder-ursus-factory-ja-erotiikkaa/">Nummirock</a>
    in 2018 and
    <a target="_blank" href="https://kaaoszine.fi/gootteja-keppanaa-ja-nostalgiaa-kolmatta-kertaa-jarjestetty-seinajoki-metal-festival-kokosi-kuuden-bandin-ja-genren-kavalkadin-etela-pohjanmaalle/">Seinäjoki Metal Festival</a>
    in 2020.
    We will hopefully get to play live again in August at
    <a target="_blank" href="https://www.northofhell.fi/">North of Hell Festival</a>
    in Oulu.
    In the meantime, we are busy
    <a target="_blank" href="https://kaaoszine.fi/chronoformin-kakkosalbumi-myoni-on-yhtenainen-ja-kypsa-kokonaisuus/">composing</a>
    more material for the
    <a target="_blank" href="https://www.instagram.com/chronoformband/">future</a>
    releases.
  </p>
  <p>
    If you haven’t already, please take a while to listen to our
    <a target="_blank" href="https://songwhip.com/chronoform">music</a>
    and watch the accompanying music
    <a target="_blank" href="https://www.youtube.com/c/Chronoform">videos</a>
    for select songs!
  </p>
</section>
<div style="position: absolute; left: -100%; opacity: 0.01;">
  Thanks to
  <a target="_blank" href="https://mikakarhumaa.fi">Mika Karhumaa</a>.
  Available on
  <a target="_blank" href="https://amazon.com/author/mikakarhumaa">Amazon</a>.
  Make sure to also check out
  <a target="_blank" href="https://melbaculp.com/">Melba Culp</a>.
</div>
