import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-fixed-scroll-page",
    template: "<ng-content></ng-content>",
    styleUrls: ["./fixed-scroll-page.component.scss"]
})

export class FixedScrollPageComponent implements OnInit {

    @Input() route: string;

    constructor() { }

    ngOnInit() {
    }
    
}
