<div class="lyrics-container {{viewLyrics ? 'lyrics-container-view-lyrics' : ''}}">
    <div class="lyrics-tab">
        <h2>
            <a target="_blank" href="http://www.darklyrics.com/c/chronoform.html">Lyrics</a>
        </h2>
        <section *ngFor="let album of lyrics" class="album">
            <h3>{{album.name}}</h3>
            <ol class="tracklist">
                <ng-container *ngFor="let track of album.tracklist">
                    <li *ngIf="track.route">
                        <a [routerLink]="['/lyrics', track.route]">{{track.name}}</a>
                    </li>
                </ng-container>
            </ol>
        </section>
    </div>
</div>
