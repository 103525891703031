import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FixedScrollComponent } from './fixed-scroll/fixed-scroll.component';
import { FixedScrollPageComponent } from "./fixed-scroll/fixed-scroll-page/fixed-scroll-page.component";
import { FixedScrollPaginationComponent } from "./fixed-scroll/fixed-scroll-pagination/fixed-scroll-pagination.component";
import { EmailLinkComponent } from './email-link/email-link.component';
import { LyricsPageComponent } from './lyrics-page/lyrics-page.component';
import { BackgroundVideoComponent } from './background-video/background-video.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { TrackLyricsComponent } from './lyrics-page/track-lyrics/track-lyrics.component';
import { BiographyPageComponent } from './biography-page/biography-page.component';
import { NewsPageComponent } from './news-page/news-page.component';

@NgModule({
    declarations: [
        AppComponent,
        FixedScrollComponent,
        FixedScrollPageComponent,
        FixedScrollPaginationComponent,
        EmailLinkComponent,
        LyricsPageComponent,
        BackgroundVideoComponent,
        LandingPageComponent,
        CopyrightComponent,
        TrackLyricsComponent,
        BiographyPageComponent,
        NewsPageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
