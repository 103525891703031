<div class="landing-page">
    <app-fixed-scroll>
        <app-fixed-scroll-page route="/">
            <app-background-video src="../../assets/BackgroundVideo.mp4"></app-background-video>
            <img class="band-logo no-select" src="../../assets/ChronoformLogo.svg">
        </app-fixed-scroll-page>
        
        <app-fixed-scroll-page route="/links">
            <div class="page-content listen-wrapper">
                <div class="spacer"></div>
                <div class="listen-row">
                    <h2>Listen</h2>
                    <a href="https://open.spotify.com/artist/634gllzJhZDQoMj9xifBtx"
                        class="listen-link no-select"
                        target="_blank">
                        <img src="../../assets/sIcon.png">
                    </a>
                    <a href="https://listen.tidal.com/artist/10725416"
                        class="listen-link no-select"
                        target="_blank">
                        <img src="../../assets/tIcon.png">
                    </a>
                    <a href="https://youtu.be/BLc-L9myZI4"
                    class="listen-link no-select"
                    target="_blank">
                        <img src="../../assets/yIcon.png">
                    </a>
                </div>
                <div class="listen-row">
                    <h2>Follow</h2>
                    <a href="https://www.instagram.com/chronoformband/"
                    class="listen-link no-select"
                    target="_blank">
                        <img src="../../assets/iIcon.png">
                    </a>
                    <a href="https://www.facebook.com/chronoformband/"
                    class="listen-link no-select"
                    target="_blank">
                        <img src="../../assets/fIcon.png">
                    </a>
                </div>
                <div class="spacer"></div>
            </div>
        </app-fixed-scroll-page>
        
        <app-fixed-scroll-page route="/biography">
            <div class="page-content">
                <app-biography-page></app-biography-page>
            </div>
        </app-fixed-scroll-page>

        <app-fixed-scroll-page route="/news">
            <div class="page-content">
                <app-news-page></app-news-page>
            </div>
        </app-fixed-scroll-page>
        
        <app-fixed-scroll-page route="/support">
            <div class="page-content">
                <h2>Support</h2>
                <div class="merch-container">
                    <div class="merch-shirt"
                        style="transform: translateX(2%) rotate(-3deg); background-image: url(../../assets/Logopaita.png);">
                    </div>
                    <div class="merch-shirt"
                        style="transform: translateX(-2%) rotate(2deg); background-image: url(../../assets/ValkoinenPaita.png);">
                    </div>
                </div>
                <section>
                    <div>
                        Online store coming soon!
                    </div>
                    <div>
                        Meanwhile, hit us up with an email at
                    </div>
                    <app-email-link address="merchandise">
                        <img height="18px" src="../../assets/MerchandiseAddress.png">
                    </app-email-link>
                </section>
            </div>
        </app-fixed-scroll-page>
        
        <app-fixed-scroll-page route="/lyrics" style="display: flex; flex-direction: row; width: 200%;">
            <div class="page-content">
                <app-lyrics-page></app-lyrics-page>
            </div>
        
            <div class="page-content">
                <app-fixed-scroll-page route="/lyrics/:track">
                    <app-track-lyrics></app-track-lyrics>
                </app-fixed-scroll-page>
            </div>
        </app-fixed-scroll-page>

        <app-fixed-scroll-page route="/contact">
            <div class="page-content">
                <h2>Contact</h2>
                <div class="bulky">
                    <section class="bylky">
                        <div>In all matters</div>
                        <app-email-link address="management">
                            <img src="../../assets/ManagementAddress.png">
                        </app-email-link>
                    </section>
                    <section class="bulky">
                        <div>For sync and licensing</div>
                        <app-email-link address="licensing">
                            <img src="../../assets/LicensingAddress.png">
                        </app-email-link>
                    </section>
                </div>
            </div>

            <app-copyright class="copyright"></app-copyright>
        </app-fixed-scroll-page>
    </app-fixed-scroll>
</div>
