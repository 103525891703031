import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TrackLyricsComponent } from './lyrics-page/track-lyrics/track-lyrics.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    children: [
      {
        path: 'links',
        children: [
          { path: '**', redirectTo: '/links' }
        ]
      },
      {
        path: 'biography',
        children: [
          { path: '**', redirectTo: '/biography' }
        ]
      },
      {
        path: 'news',
        children: [
          { path: '**', redirectTo: '/news' }
        ]
      },
      {
        path: 'support',
        children: [
          { path: 'catalog', children: [] },
          { path: '**', redirectTo: '/support' }
        ]
      },
      {
        path: 'lyrics',
        children: [
          { path: ':track', children: [] },
          // { path: '**', redirectTo: '/lyrics' }
        ]
      },
      {
        path: 'contact',
        children: [
          { path: '**', redirectTo: '/contact' }
        ]
      }
    ]
  },
  {
    // Redirect invalid routes
    path: '**',
    // to landing page
    redirectTo: ''
  }
];

const options: ExtraOptions = {
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
