import { AfterContentInit, Component, ContentChildren, ElementRef, QueryList, ViewChild } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import * as Hammer from "hammerjs";
import { FixedScrollPageComponent } from "./fixed-scroll-page/fixed-scroll-page.component";

@Component({
    selector: "app-fixed-scroll",
    templateUrl: "./fixed-scroll.component.html",
    styleUrls: ["./fixed-scroll.component.scss"],
    host: { "(window:resize)": "updateTransform()" }
})

export class FixedScrollComponent implements AfterContentInit {

    @ViewChild("fixedScrollWrapper", { static: true }) fixedScrollWrapper: ElementRef;
    @ViewChild("background", { static: true }) background: ElementRef;
    @ContentChildren(FixedScrollPageComponent) pages: QueryList<FixedScrollPageComponent>;

    page: number = 0;
    horizontalPage: number = 0;
    scrollCooldown: number = 250;
    scrollTime: number;

    constructor(private router: Router) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart)
                this.onNavigation(event.url);
        });
    }

    ngAfterContentInit() {
        this.initScroll();
        this.onNavigation(this.router.url)
    }

    ngOnDestroy() {
        window.removeEventListener("wheel", this.scroll);
        window.removeEventListener("keydown", this.arrowKey);
    }

    onNavigation = (url) => {
        // Get page with route
        let page = null;

        this.hack(url);

        this.pages.find((item, index) => {
            if (this.isActiveRoute(url, item.route)) {
                page = index;
                return true;
            }
            return false;
        });

        if (!isNaN(page) && page !== this.page)
            this.setPage(page);
    };

    hack = (url) => {
        // Hack horizontal scroll on lyrics page
        if (/\/lyrics\/[a-z\-]/.test(url))
        {
            this.horizontalPage = 1;
            this.updateTransform();
        }
        else
        {
            this.horizontalPage = 0;
            this.updateTransform();
        }
    };

    isActiveRoute = (source: string | string[], target: string | string[]) => {
        if (!source) {
            source = [""];
        }
        else if (!Array.isArray(source)) {
            source = source.split("/")
            if (!source[0])
                source.splice(0, 1);
        }
        if (!target) {
            target = [""];
        }
        else if (!Array.isArray(target)) {
            target = target.split("/");
            if (!target[0])
                target.splice(0, 1);
        }
        
        for (const i in target) {
            if (source[i] !== target[i])
                return false;
        }
        return true;
    }

    initScroll = () => {
        window.addEventListener("wheel", this.scroll);
        window.addEventListener("keydown", this.arrowKey);

        // Setup swipe
        const swipeElement = new Hammer(this.fixedScrollWrapper.nativeElement);
        swipeElement.get("swipe").set({direction: Hammer.DIRECTION_VERTICAL});

        swipeElement.on("swipeup", this.nextPage);
        swipeElement.on("swipedown", this.previousPage);
    };

    scroll = (event: WheelEvent): void => {
        const now = Date.now();

        if (event.deltaY > 0 && (this.scrollTime || 0) + this.scrollCooldown <= now) {
            this.nextPage();
            this.scrollTime = now;
        }

        if (event.deltaY < 0 && (this.scrollTime || 0) + this.scrollCooldown <= now) {
            this.previousPage();
            this.scrollTime = now;
        }
    };

    arrowKey = (event: KeyboardEvent) => {
        switch (event.code) {
            case "ArrowUp":
                this.previousPage();
                break;
            case "ArrowDown":
                this.nextPage();
                break;
        }
    };

    nextPage = () =>  this.setPage(this.page + 1);
    previousPage = () => this.setPage(this.page - 1);

    setPage = (page: number) => {
        this.page = Math.min(Math.max(page, 0), this.pages.length - 1);
        this.updateRoute();
        this.updateTransform();
    };

    updateRoute = () => {
        const page = this.pages.get(this.page);
        const route = page?.route || "";
        this.router.navigate([route])
    };

    updateTransform = () => {
        const x = -this.horizontalPage * this.fixedScrollWrapper.nativeElement.offsetWidth;
        const y = -this.page * this.fixedScrollWrapper.nativeElement.offsetHeight;
        this.fixedScrollWrapper.nativeElement.style.transform = `translate(${x}px, ${y}px)`;

        const backgroundX = -this.horizontalPage / (2 - 1) * 50;
        const backgroundY = -this.page / (this.pages.length - 1) * 387;
        this.background.nativeElement.style.transform = `translate(${backgroundX}px, ${backgroundY}px)`;
    };
}
