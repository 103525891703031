export interface Album {
    name: string;
    releaseDate: Date;
    tracklist: Track[];
};

export interface Track {
    name: string;
    route?: string;
    lyrics?: string;
    link?: string;
};

const lyrics: Album[] = [
    {
        name: 'From the Void',
        releaseDate: new Date(2019, 4, 29),
        tracklist: [
            {
                name: 'Conquer',
                route: 'conquer',
                lyrics:
`Every step I take towards the edge
Makes me stronger
This will be the reckoning
I will push myself
Over
We'll go further!
Follow me I'm conquering

Full force now, I won't quit
Back to back, standing tall
Among the giants
Rising with, wave of strength
Bringing life inside the veil of
Darkness

Come, take my hand
Together we will make the change
Forget the lines
You are so deeply blinded in
Take my hand
Follow us
Take my hand
Forget the rules

Full force now, I won't quit

Back to back, standing tall
Among the giants
Rising with, wave of strength
Bringing life inside the veil of
Darkness
Follow me I'm conquering
This will be the reckoning
Follow me I'm conquering

I will burn the bridge under the false idols of today
Come on and see
The new dawn of the pure and great
I will make the throne of dismay submit to the deepest hate
We will conquer in waves

Come, take my hand
Together we will make the change
Forget the lines
You are so deeply blinded in
Take my hand!
Follow us!
Take my hand!
Forget the rules!

This day will be remembered as the day we make the change
As the day we make the change`
            },
            {
                name: 'Sight of Eternity',
                route: 'sight-of-eternity',
                lyrics:
`You will
Search the light
Inside the hollowness
I will show the way
Finding lines to pursue

Like the first ray
Of sunlight passes through the space
Sense of safety
Unveils in the center of mess
Words of known kind
Are not enough
To portray the type
Your presence tranquils
The ocean of fire
Mind of madness
Will bow the sight of eternity
Essence of you
Will make the tempest kneel

Calm inside the storm
Voice of yours
Resonating

Extinguish the dissonance
The defect ruining
Conciliation
Break the barrier of declination
The bringer of constellation
The bringer of constellation

Raise the boulder
Crushing sight of a blind alley
Make my eyes
Contemplate the purity
Your being illuminates
Through the end of eternity

Extinguish the dissonance
The defect ruining conciliation
Break the barrier of declination
The bringer of constellation
The bringer of constellation

Conceal me
Guide me
Relieve me
Salvage me
Salvage me

Salvage me
Relieve me
Salvage me`
            },
            {
                name: 'Limbo',
                route: 'limbo',
                lyrics:
`Two entities engage in a dance of death
Slowly filling the gaps inside their morbid minds
Solutions to false problems
Created by a twisted – vision
Unable to see what's truly wrong

When the time comes
I won't look back
All the tears, all the fears
Gone forever
When something dies
There's no return
Restart, rebuild
Rise from the ashes
Again

The anger of the beast
Kept in a chain
Soon turned to distant shouts
Attempts in vain
Liquify the agony
Dilute the pain
The only thing that matters
Is that I reign

I will not let you destroy me now
I will not let you destroy me now
Rise from the ashes
Restart, rebuild

Broken wings fly low
Broken souls heal slow
Coming back stronger
Is the only goal
No matter if it takes a lifetime
One will grow`
            },
            {
                name: 'Veil',
                route: 'veil',
                lyrics:
`An alien being with a delicate composure
Walking slowly towards me
Intricate details hide the weakness
Masked beneath the surface
Approach or assess from the distance
Should I run or get closer
Frightening yet so beautiful

Revealing itself piece by piece
Desire taking over my emotions
Could it be that there is a way for us
To communicate
After centuries of silence
I've found the key

Sophisticated, gentle, immersed in light
You are a masterpiece
Crafted with precision
So near yet so far
Unable to perceive your deepest purpose
It is still a mystery

After waiting for so long
It is finally time to unveil it all
Speaking in foreign languages
We try to make each other see

The similarities
That make us one
Overcome the hurdles
They prove them wrong
What's found shall never be
Concealed again, or we might
Lose control

Revealing itself piece by piece
Desire taking over my emotions
Could it be that there is a way for us
To communicate
After centuries of silence
I've found the key
After centuries of silence
I finally found the key`
            },
            {
                name: 'Purist',
                route: 'purist',
                lyrics:
`Soulless lambs step
Into the market spinning wheel
Roots – destroyed
Follow the money and kick their wheels
Destroy the legacy
You're worthless in my eyes
Collect the paycheck
Stained by your own
Greed

Crawl
Through the hole
Forget everything
Squirm in their leash
Without a meaning
Forget everything
Without a meaning

Stain yourself
Mark your doors
The cleansing will
Take the fools
No hope for saints
Serving gods
Made by men
Worshiping material

Forget everything
Without a meaning
Forget everything

Take your money
Burn it in the faithless fire
I pity you
Drowning in the field of lies
Fake your success
Possible by the corporate reels
I am a purist
You are just worthless
Take your money
Throw it into endless wheel
Hang in the noose
You have just your poor self in`
            },
            {
                name: 'Prophets',
                route: 'prophets',
                lyrics:
`Core of the earth

Core of the earth will burn to ash today
Sky is red and the sun is fading away

Kneel down for the prophets
Descending, destroying
Kneel down for the prophets
Wiping the soil
Kneel down for the kingdom
Descending, destroying
Kneel down for the prophets

Burn away
The soil will burn today
Burn away
No soul will walk another day

Remains of the throne will see a new morning
Catalyst for the great is now unfolding

Kneel down for the prophets
Descending, destroying
Kneel down for the prophets
Wiping the soil
Kneel down for the kingdom
Descending, destroying
Kneel down for the prophets

Burn away
The soil will burn today
Burn away
No soul will walk another day`
            },
            {
                name: 'From the Void',
                route: 'from-the-void',
                lyrics:
`From the void
Warm darkness soothing emptiness
Soon to end
Reborn from the tears of humanity
Stars align beneath the sky
In tragedy

Glaze upon the fall
Of clarity

Formed in hate
By the choice of will
Force unseen
Is it in us
Driven by one
Anomality

A machine called human

Woken from beneath
Rise of supremacy
From beneath
Supremacy

Formed in hate
By the choice of will
Force unseen
Is it in us
Driven by one
Anomality

A machine called human
A machine called human`
            },
            {
                name: 'Dusk',
                route: 'dusk',
                lyrics:
`Man-made disasters
Taking place every day
Are you willing
To risk your life
Put everything
On the line
In exchange of
Progress

The outcome in the end
Is not always so easy
To see
Underestimation
Leads to an early end

Alone in the dark
The weight of my eyelids
Pulling me down
Into infinity

Stay awake
Keep your eyes peeled

Lurking in the shadows
Danger's coming at you
Lurking in the shadows
Danger's coming at you

Like a stone being hurled across the sky
Numb and powerless you fly
Was there ever a chance you might have
Reconsidered it

No way out
No way out

Will I
Ever find a way
Out of here

Reaching the limit
Passing the line
Consciousness follows
Give me a sign
Never again
Will I do the same
If where I lay
Is not my grave`
            },
            {
                name: 'Subatomic',
                route: 'subatomic',
                lyrics:
`Route to find the birth of man
The place without time and space

Change in known reality
Forced the evident fall
Too big for human mind to see
No one will ever recall

First to between molecules
Link in the DNA
Course to infinite sanity
Foreseen instant decay

Route to find the birth of man
The place without time and space
Context so deep inside of one
Borders will arise

Dig under the skin
To find the missing piece
Link between man and nature
The solace

Route to find the birth of man, the birth of man
The place without time and space, time and space`
            }
        ]
    },
    {
        name: 'Myoni',
        releaseDate: new Date(2020, 8, 25),
        tracklist: [
            {
                name: 'Delusive Solace',
                route: 'delusive-solace',
                link: 'https://www.inferno.fi/uutiset/metalcoreyhtye-chronoform-esittelee-kakkosalbumiaan-aggressiivinen-delusive-solace-kertoo-todellisuuden-kieltamisesta/',
                lyrics:
`Distort the signals
Damaging your truth
Turn away the light
Conceal yourself in ignorance

Guided by misconceptions
Fueled by the mind
Artificial perception
Makes you go blind

Kneeling down in anguish
You're anxious, overwhelmed

Conditions progressing
Crushing you to the ground
You're in the middle of nowhere
It's hard to see the world from
Behind the walls you built
It took a lifetime to get in

Will those walls ever let you free

Renounce the crown of thorns
Disengage and see
With gold you were once adorned
What's left is debris

With smoke-filled lungs you
Take a breath
Say everything is fine
Creating a forged reality
Differing from mine`
            },
            {
                name: 'Obsession',
                route: 'obsession',
                link: 'https://www.inferno.fi/uutiset/chronoform-kasittelee-uudella-videollaan-riippuvuuksia-naennaisesti-voidaan-olla-tyytyvaisia-tilanteeseen-vaikka-totuus-saattaa-olla-taysin-toinen/',
                lyrics:
`Surreally embraced
Falsely feeling calm
With everything you do
The walls fall apart
Rebuild, once again
Betrayal of the heart
Which way to take,
Which move to make
Where to start

Trust built on false grounds
Momentary measures
How long will it last
Is a question one must ask

Awaiting the fate
In a feeble state
No will to alter
The destination
Of these trails
As the warmth prevails
Turning a blind eye
To the obsession
Forced to look away
Deep down below
Oh, so hollow
Torn apart inside

Foundations built on sand
The delusions were so grand

Foundations built on sand
The delusions were so grand

Foundations built on sand
The delusions were so grand

Surreally embraced
Falsely feeling calm
With everything you do
The walls fall apart
No falling back now
You've got the shortest straw
There's no one to blame
You were the only one to draw`
            },
            {
                name: 'The Paradox',
                route: 'the-paradox',
                link: 'https://metalliluola.fi/chronoform-julkaisi-uuden-mielenterveysongelmia-syrjaytymista-seka-riippuvuutta-kasittelevan-singlen-ja-musiikkivideon-the-paradox/',
                lyrics:
`I
Never found a solution
To these paradoxes
Simultaneously true and false
Gasping for air in this
State of confusion

Left unsolved
Left to be, the
Never-ending questions
Answers, will they find a way
To resurface
Will they ever show themselves

I'll find a way
To end this for good
The sanctuary
Awaits while we float towards the bliss

Diverted by temptation
Pulled back by memories
The mind wanders,
It just wants you to feel
Explore the unknown,
The distant seas
Sometimes it takes a lifetime to
Build the courage to make the leap

I'll find a way
To end this for good
The sanctuary
Twaits while we float towards the bliss

Bound by the past
How this all could end so fast
This world, oh so vast
Is mine to roam at last

These broken wings of mine will
Carry me just right
Everything I needed
Was hiding in plain sight`
            },
            {
                name: 'Mirrors',
                route: 'mirrors',
                lyrics:
`Mirror
Is it there to see
What you've become
What you need to be free

Mirror
Is it there to see
What you've become
What you need to be free

Look deeper
Dig in to yourself
Are you hollow
Or just defective breed

Reflecting rays with mass
Pierce your skin
You lose the grasp

Look what you
Did to yourself
Are you hollow
Or just defective breed

Reflecting rays with mass
Pierce your skin
You lose the grasp

It is not enough
Orientation
Slowly falling off

It is not enough
Formation
Turning in the dust

Forward
Turn the tide
Become the catalyst
Retaliate

Forward
Turn the tide
Become the catalyst
Retaliate

Forward

Mirror
Is it there to see
What you've become
What you need to be free

It is not enough
Orientation
Slowly falling off

It is not enough
Formation
Turning in the dust`
            },
            {
                name: 'Melancholic Minds',
                route: 'melancholic-minds',
                lyrics: '[Instrumental]'
            },
            {
                name: 'Myoni',
                route: 'myoni',
                lyrics:
`The story, no conclusion
Just empty pages
Hollow thoughts fill my mind
With blank spaces

My legacy, it disappears
It's nonexistent
No triumph, no defeat
Meaningless, yet so consistent
I've been running for so long
The roots never had time to grow,
Now I know

My eternity
Might be longer than
Yours
Oblivious souls
In a world unknown

Please, remember me
If ever there's a time you end
In grief

As the metal starts to rust
Our bodies turn to dust
Feel the cold breeze
Upon my skin

I will die
I will die

No more distortion
The curtain falling down
Reveals a figure
In a shiny gown
I take a closer look,
Squint my eyes and see
It's my reflection
Staring back at me

The wings of butterflies
Create a giant storm
Everything I've done
Has modified my form
Now clear, once obscure
My life was true
As death revealed,
I've made it through

I will continue
Filling the blank pages
With all my memories
Created in the past
My eternity
Might be over on Earth
But I will go on`
            },
            {
                name: 'Shades of Blue',
                route: 'shades-of-blue',
                lyrics:
`These tidal waves of sorrow
Came crashing down my spine
The monsoon of tears
Slowly building up
Beneath my broken smile

Blindsided
Caught out of the blue
Without a hand to reach for
It's time to see this through

The storm is rising
Your ship will sink
A lonely lifeboat
Can't carry us all
Immobilizing
The waves will undo
Decompose the structures
In the shades of blue

Inhale the sea
Set me free

The storm is rising
Your ship will sink
A lonely lifeboat
Can't carry us all
Immobilizing
The waves will undo
Decompose the structures
In the shades of blue

In the horizon
A tiny glimpse of light
The sole beacon,
Flash of hope
In the night
In the turn of tides
I've come to realize
The suffering we shared
Opened my eyes

The leaves have withered
Your roots have died
Slowly faded out
Beneath the sky
Once so bright

We missed the warning signs
Ignored the flashing lights
What came to be
No one could foresee`
            },
            {
                name: 'Bloom',
                route: 'bloom',
                lyrics:
`The years went so fast
I can see it now
As I pick up the pieces

Ones that used to
Made us feel
Was everything and everywhere
Now nothing fills everything
And everything is

Nothing
If I could I would do a miracle
To see you thrive and smile again
Even if my bones break or the gravity fades
Or the words won't help

Hate how powerless I feel
And felt as the glass
Started shattering
Wish I knew the ways
As the glow in the soul started fading

Like a drop in the ocean
Nothing is one and all is foreign

Floating in a shapeless image
With memories and grief
What used to be

It will be found again in nothingness
As the moons go by and the rain will ease
The image will be clear again
As the power you have will blossom
Like a flower rising from the burnt woods
Beautiful as ever been

The soil is cleaned by fire
It's the perfect rebirth
As the earth mourns
And pillars of smoke
Rise to the sky
Bloom will follow

Ashes are so gray
But they are inevitable

To carry a new world
Better than ever before`
            }
        ]
    }
];

export default lyrics;
