import { Component } from '@angular/core';

@Component({
  selector: 'app-copyright',
  template: 'Copyright © {{date | date: "yyyy"}}, Chronoform',
})
export class CopyrightComponent {

  date: Date = new Date();

  constructor() { }

}
