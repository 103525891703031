<div #background class="background"></div>
<div #fixedScrollWrapper class="fixed-scroll-wrapper">
    <ng-content select="app-fixed-scroll-page"></ng-content>
</div>

<app-fixed-scroll-pagination
    [page]="page"
    [pages]="pages"
>
</app-fixed-scroll-pagination>
