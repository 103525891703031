import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-background-video',
  templateUrl: './background-video.component.html',
  styleUrls: ['./background-video.component.scss']
})
export class BackgroundVideoComponent implements OnInit {

  @Input() src: string;
  @ViewChild("backgroundVideo", { static: true }) backgroundVideo: ElementRef<HTMLVideoElement>;

  constructor() { }

  ngOnInit(): void {
    // Fix video autoplay for Chrome
    this.backgroundVideo.nativeElement.muted = true;
  }

}
