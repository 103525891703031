import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import lyrics, { Track } from '../lyrics';

@Component({
  selector: 'app-track-lyrics',
  templateUrl: './track-lyrics.component.html',
  styleUrls: ['./track-lyrics.component.scss']
})
export class TrackLyricsComponent implements OnInit {

  @ViewChild("preventSwipe", { static: true }) preventSwipe: ElementRef;

  activeTrack: Track;
  sub: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {
    // this.preventSwipe.nativeElement.addEventListener("wheel", (ev) => {
    //   console.log("moro");
    //   ev.stopPropagation();
    // });
  }

  ngOnInit(): void {
    this.getActiveTrack();
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd)
        this.getActiveTrack();
    });
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }

  getActiveTrack = () => {
    // Dirty hack for now: Get track route parameter from route.firstChild.firstChild
    if (this.route.firstChild?.firstChild) {
      const trackRoute = this.route.firstChild.firstChild.snapshot.params.track;
      this.activeTrack = this.getTrackByRoute(trackRoute);
    }
  }

  getTrackByRoute = (route: string) => {
    const allTracks: Track[] = [].concat.apply([], lyrics.map(album => album.tracklist));
    return allTracks.find(track => track.route === route);
  }
  
}
