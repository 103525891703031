import { Component, OnInit } from '@angular/core';
import lyrics, { Album } from './lyrics';

@Component({
  selector: 'app-lyrics-page',
  templateUrl: './lyrics-page.component.html',
  styleUrls: ['./lyrics-page.component.scss']
})
export class LyricsPageComponent implements OnInit {

  // TODO: NYT ON KAHTEEN KERTAAN lyrics-page-COMPONENT
  // PARAMS EI TOIMI JOS EI OLE /LYRICS ROUTELLA COMPONENTTIA
  // MUTTA NYT BUGAA KOSKA SEKÄ LANDING PAGELLA ETTÄ ROUTER-OUTLETISSA ON lyrics-page-COMPONENT
  // SILLE TÄYTYY TEHDÄ KOMPONENTTI JOKA ON PELKÄSTÄÄN SE OIKEANPUOLIMMAINEN SIVU
  // JA ASSIGNATA SE /LYRICS ROUTELLE KOMPONENTIKSI
  // JA UUDELLEENNIMEÄ TÄMÄ KOMPONENTTI lyrics-page => LYRICS-PAGE

  lyrics: Album[] = lyrics;
  viewLyrics: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }
  
}
